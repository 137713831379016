<template>
  <div class="page-404">
    <el-empty description="找不到该页面">
      <el-button type="primary" @click="handleBack">返回</el-button>
    </el-empty>
  </div>
</template>

<script setup>
import { getCurrentInstance } from "vue";
import { STORE_NAME_AUTH_TOKEN } from "@/utils/storageTables";

const { proxy } = getCurrentInstance();

const mainStore = proxy.$usePiniaModule("mainStore");

// 操作返回
const handleBack = async () => {
  // token
  const token = proxy.$getItem(STORE_NAME_AUTH_TOKEN);

  if (token) {
    // 重置
    // 重定向
    mainStore.initRedirect = true;
    proxy.$router.go(-1);
  } else {
    proxy.$router.replace("/login");
  }
};
</script>

<style lang="scss">
.page-404 {
  width: 100vw;
  height: 100vh;
  .el-empty {
    width: 100%;
    height: 100%;
  }
}
</style>
